export const environment = {
	production: false,
	staging: false,
	development: true,
	api_bridgeEndpoint: 'https://dev-br.nexus.visionix.com',
	nexusLanding: 'https://landing.visionix.com',
	nexusLoginUrl: 'https://dev-web.nexus.visionix.com/',
	apiEndpoint: 'https://dev-api.nexus.visionix.com',
	brand: 'Default',
	brandTitle: 'Nexus - Subscribe',
}
